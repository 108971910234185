import React, { Component } from "react";
import { graphql } from "gatsby";
import "../css/rahul-styles.css";

import PopupForm from "../components/popupForm";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Sharing from "./../components/Sharing";
import TableOfContent from "./../components/TableOfContent";
class StudyResource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
            programUrl: props.path
		};
	}
	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};
	// TableContent = () => {
	// 	let wrapperElement = document.querySelector('.blog-detail-topic-para-text')
	// 	console.log(wrapperElement.innerHTML)
	// }
	// TableContent();
	render() {
		var filteredRes = [];
		const post = this.props.data.wordpressWpBlog;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
	const focus_keyphrase = post.acf.focus_keyphrase; 
	const canonical_url = post.acf.canonical_url; 
		this.props.data.wordpressWpBlog.blog_categories.forEach((element) => {
			this.props.data.allWordpressWpBlog.edges.forEach((data) => {
				if (filteredRes.length < 4) {
					if (data.node.blog_categories.includes(element) && filteredRes.includes(data) == false) filteredRes.push(data);
				}
			});
		});

		const author = post.author;

		const authorName =author.name;
		const authorBio = author.description;
		const authorPic = post.author && post.author.acf && post.author.acf.profile_picture ? post.author.acf.profile_picture.source_url : "https://assets.theworldgrad.com//wp-content/uploads/2024/03/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg";


	 // Calculate reading time
	 const wordsPerMinute = 200;
	 const wordCount = post.acf.content ? post.acf.content.split(/\s+/).length : 0;
	 const calculatedMinutes = Math.ceil(wordCount / wordsPerMinute)+1;
	 const calculatedReadingTime = `${calculatedMinutes} ${calculatedMinutes === 1 ? "minute" : "minutes"}`;
	 const readingTime = post.acf.reading_time || calculatedReadingTime;
	 const pageURL =  `https://theworldgrad.com${this.state.programUrl}`;


const imageUrls = [];


const extractImageUrls = (content) => {
	const imgRegex = /<img[^>]+src=['"]([^'">]+)['"]/gi;
	const imageUrls = [];
	let match;
	
	if (post.featured_media && post.featured_media.source_url) {
		imageUrls.push(post.featured_media.source_url);
	  }

	// Iterate over matches
	while ((match = imgRegex.exec(content)) !== null) {
	  const url = match[1];
	  if (!imageUrls.includes(url)) {
		imageUrls.push(url);
	  }
	}
	return imageUrls;
  };

  const extractedImageUrls = extractImageUrls(post.acf.content);

  function formatDate(date, ISO = false) {
	// Ensure date is a valid Date object
	if (!(date instanceof Date)) {
	  date = new Date(date);
	}
  
	// Log the original date input (optional)
	console.log("GraphQL Query Date:", date);
  
	// Get individual components of the date
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  
	// Return formatted date
	if (ISO) {
	  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
	} else {
	  return `${day}-${month}-${year}`;
	}
  }


  
  //console.log("JSON String ->"+JSON.stringify(extractedImageUrls));
	 const schema = `{
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"mainEntityOfPage": {
		  "@type": "WebPage",
		  "@id": "${pageURL}"
		},
		"headline": "${post.title}",
		"name": "${post.title}",
 "image": ${JSON.stringify(extractedImageUrls)},
 
 
		"datePublished": "${formatDate(post.date, true)}",  
		"dateModified": "${formatDate(post.modified, true)}",  
		"author": {
		  "@type": "Person",
		  "name": "${authorName || 'The WorldGrad'}",  
		  "url": "${authorPic || 'https://assets.theworldgrad.com/gatsby-assets/images/logo.png'}"  
		},
		"publisher": {
		  "@type": "Organization",
		  "name": "The WorldGrad",
		  "logo": {
			"@type": "ImageObject",
			"url": "https://assets.theworldgrad.com/gatsby-assets/images/logo.png" 
		  }
		},
		"hasPart": []
	  }
	  `;
	  
	  //console.log("JSON imageList->"+schema);
		return (
			<div>
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          {(() => {if(post.acf.canonical_url != '' && post.acf.canonical_url != null) {return (<link rel="canonical" href={canonical_url} /> )} })()} 
		  <script type="application/ld+json">
                        
                        {JSON.stringify(JSON.parse(schema))}
                    
                    </script>





          <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />


					</Helmet>
					{/* <Header /> */}
					<PopupForm time={this.state.time} />
					<div className="container newStyle">
						<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
							<li class="breadcrumb-item">
								<a href="/">Home</a>
							</li>
							<li class="breadcrumb-item">
								<a href="/study-resources/">Study Resources</a>
							</li>
							<li class="breadcrumb-item active">{ReactHtmlParser(post.title)}</li>
						</ol>
					</div>
					<div className="container-fluid blog-detail-container">
						<div className="row blog-detail-main-heading-text-row">
							{post.featured_media != null ? (
								<div className="col-12">
									<div className="blog-banner-img">
										<img src={post.featured_media.source_url} className="mb-3" alt={ReactHtmlParser(post.title)} />
									</div>
								</div>
							) : (
								""
							)}
							<div className="col-md-6 col-lg-10">
								<h1 className="blog-detail-main-heading-text">{ReactHtmlParser(post.title)}</h1>
								<div className="my-1 d-flex flex-wrap">
									<span className="blog-detail-sub-heading">
										Reading Time <strong>{readingTime  || "0"}</strong>
									</span>
									<span className="mx-3 text-muted">/</span>
									<span className="blog-detail-sub-heading">
						
									{post.blog_categories.includes(21) ? (
										<span>
										Published on <strong>{formatDate(post.date)}</strong>
									  </span>
  
) : (
	<span>
    Updated on <strong>{post.modified ? formatDate(post.date) : formatDate(post.date)}</strong>
  </span>
)}
</span>
								</div>
								<div className="social-share-wrapper mt-3">
									<Sharing />
								</div>
								{/* <h3 className="blog-detail-sub-heading"> Blogs / {post.acf.select_category[0]} /
                 {
                post.acf.select_category.map(({cat,i}) => {
         
                    return(cat) })
                }
                {ReactHtmlParser(post.acf.description)} </h3> */}
							</div>
						</div>
						<div className="row mx-0">
							<div className="card shadow w-100 blog-detail-main-card">
								{/* <img className="img-fluid blog-detail-library-img blog-detail-mansonry-image" src={post.featured_media.source_url} alt="Books" /> */}
								<div className="row blog-detail-card-content mt-10 align-items-start">
									<div className="col-md-8 blog-detail-topic-body">
										<p className="blog-detail-topic-para-text">{ReactHtmlParser(post.acf.description)}</p>
										<TableOfContent />
										<p className="blog-detail-topic-para-text">{ReactHtmlParser(post.acf.content)}</p>
										{/* <h5 className="blog-detail-topic-heading">
                      Lorem ipsum dolor sit amet, consectetur adipiscing eli
                    </h5>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.
                    </p>
                    <p className="blog-detail-topic-para-heading">
                      Lorem ipsum dolor sit ameti
                    </p>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.
                    </p>
                    <img src={laptopImg} alt="laptop-image" />
                    <p className="blog-detail-image-label mt-2"> Image: Lorem ipsum dolor sit ameti </p>
                    <p className="blog-detail-topic-para-heading">
                      Lorem ipsum dolor sit ameti
                    </p>
                    <p className="blog-detail-topic-para-text" >
                      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione.
                    </p> */}
						<div class="container"	>					
<div class="row author-box">
<div className="col-md-2  author-image">
 <img 
        src={authorPic}
        alt={authorName}  
    /><br/>
										
			

</div>
<div className="col-md-10  author-description">

<p class=" font-weight-600 font-size-18 mb-2">Author: <span class="color-blue">{authorName}</span></p>
									<p>{authorBio}</p>
								</div>


</div>

									</div>

		</div>
	
		
	

									<div className="col-md-4 blog-detail-sidebar-wrapper">
										<div className="row blog-detail-side-menu">
											<div className="col-sm-6 col-md-12 mb-4">
												<div className="card shadow blog-detail-similar-blogs-card">
													<p className="blog-detail-similar-blogs"> Similar Blogs </p>
													{filteredRes.map(({ node }, index) => {
														return (
															<div>
																<a href={`/study-resources/${node.slug}/`}>
																	<p className="blog-detail-similar-blogs-title"> {ReactHtmlParser(node.title)} </p>
																</a>
																<p className="blog-detail-similar-blogs-posted-date">
																{node.blog_categories.includes(21) ? (
										<span>
										Published on  {node.date}
									  </span>
  
) : (
	<span>
    Updated on {node.modified ? node.modified : node.date}
  </span>
)}</p>
																{index != filteredRes.length - 1 && <hr style={{ color: "#626E81" }} />}
															</div>
														);
													})}

													{/* <a href="#"> <p className="blog-detail-similar-blogs-title"> natus error sit voluptatem totam rem aperiam, eque </p> </a>
                          <p className="blog-detail-similar-blogs-posted-date" >
                            Posted on April 12, 2021
                          </p>
                          <hr style={{ color: "#626E81" }} />
                          <a href="#"> <p className="blog-detail-similar-blogs-title"> natus error sit voluptatem totam rem aperiam, eque </p> </a>
                          <p className="blog-detail-similar-blogs-posted-date" >
                            Posted on April 12, 2021
                          </p> */}
												</div>
											</div>
											{/* <div className="col-sm-6 col-md-12 blog-detail-interested-container">
												<div className="card shadow blog-detail-form-enquiry-card">
													<p> Interested in what you see? Get started and study in your preferred destination? </p>
													<a href="#" className="text-center">
														<button type="button" className="btn blog-detail-interested-button" onClick={() => this.handlePopupWindow()}>
															GET STARTED
														</button>
													</a>
												</div>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="row blog-detail-form">
              <div className="col-md-6">
                <div className="row ">
                  <div className="col-12">
                    <p className="blog-detail-form-heading">
                      Leave a comment
                    </p>
                  </div>
                  <div className="col-md-6 blog-detail-form-inputs">
                    <label className="form-label" > FIRST NAME </label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="col-md-6 blog-detail-form-inputs">
                    <label className="form-label" > E-MAIL </label>
                    <input className="form-control" type="email" />
                  </div>
                  <div className="col-12 blog-detail-form-inputs">
                    <label className="form-label" > COMMENTS </label>
                    <textarea className="form-control" rows="3" type="text" style={{border: 'none', borderRadius: "10px"}} > </textarea>
                  </div>
                <a href="#">
                    <button
                      type="button"
                      className="btn btn-warning blog-detail-comment-button"
                    >SUBMIT</button>
                  </a>
                </div>
         
              </div>
            </div> */}
					</div>
					{/* <Footer /> */}
				</Layout>
			</div>
		);
	}
}

export default StudyResource;
export const WorldgradBlogQuery = graphql`
	query currentWorldgradBlogQuery($id: String!) {
		wordpressWpBlog(id: { eq: $id }) {
			id
			title
			slug
			blog_categories
		 date(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
  modified(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
			featured_media {
				source_url
			}
			acf {
				reading_time
				description
				meta_title
meta_description
focus_keyphrase
canonical_url

				content
				dynamic_schema
			}
			author {
				description
				name
				acf {
				  profile_picture {
					source_url
				  }
				}
			  }
			}
		allWordpressWpBlog {
			edges {
				node {
					id
					title
					slug
					blog_categories
					date(formatString: "DD-M-YYYY")
					modified(formatString: "DD-M-YYYY")
				}
			}
		}
	}
`;

// select_category below date
